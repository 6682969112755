<template>
  <mega-card class="rounded h-100 mb-0">
    <div
      class="navbar border-bottom rounded-top p-relative"
      :style="
        userData['idt_user'] ? 'background-color: rgba(2,140,255, 0.27)' : ''
      "
    >
      <div
        @click="
          $route.name === 'Dashboard'
            ? $emit('open-qr', userData['idt_user'])
            : (modal = !modal)
        "
      >
        <IconBase
          viewBox="0 0 24 24"
          :width="24"
          :height="24"
          class="qr-scan cursor-pointer text-muted"
          style="z-index: 10"
          :iconName="$t('qr_code')"
          ><ScanQR
        /></IconBase>
      </div>
      <div class="container-fluid py-3">
        <router-link
          :to="{ name: 'User', params: { id: user ? user.idt_user : '' } }"
          class="w-100 navbar-label mr-auto text-dark"
        >
          <mega-image
            class="rounded-full navbar-avatar mr-3"
            small
            ratio="1x1"
            :src="userData.avatar"
            style="overflow:hidden;"
          />

          <div class="content" style="width: calc(100% - 78px);">
            <small class="strong text-overflow"
              >{{ userData.name || "- - -" }}
              <IconBase
                v-if="userData.verified"
                viewBox="0 0 312 311"
                :width="15"
                iconColor="#45C1C9"
                ><UserVerified /></IconBase
            ></small>
            <small class="text-muted text-overflow">
              <span
                :title="userTitle"
                v-if="device"
                :class="{ 'text-success': device === 'i-android' }"
              >
                <i :class="device"></i> -
              </span>

              {{ userData.username }}
            </small>
          </div>
        </router-link>
      </div>
      <div @click="$refs['promo'].open()">
        <IconBase
          v-if="!userData.influencer"
          viewBox="0 0 512 512"
          :width="24"
          :height="24"
          class="promo-code mt-auto ml-auto mr-1 cursor-pointer text-muted"
          style="z-index: 10"
          :iconName="$t('promocode')"
          ><PromoCode
        /></IconBase>
      </div>
    </div>

    <nav class="nav row font-small w-100 pl-3">
      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-mail nav-icon"></i>
        <a
          :class="{ 'events-disable text-dark': !userData.email }"
          :href="userData.email && 'mailto:' + userData.email"
          >{{ userData.email || "- - -" }}</a
        >
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-eye nav-icon"></i>
        <span>{{ roundviews || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-phone nav-icon"></i>
        <a
          :class="{ 'events-disable text-dark': !userData.phone }"
          :href="userData.phone && 'tel:' + userData.phone"
          >{{ userData.phone || "- - -" }}</a
        >
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-heart nav-icon"></i>
        <span>{{ roundlikes || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-8 p-0 pl-2">
        <i class="i-user-plus nav-icon"></i>
        <span>{{ $u2d(userData["a_time"], "DD.MM.Y") || "- - -" }}</span>
      </div>

      <div class="nav-text text-muted col-4 p-0">
        <i class="i-layers nav-icon"></i>
        <span>{{ userData["round_count"] || "- - -" }}</span>
      </div>
    </nav>
    <mega-modal
      ref="qr"
      class="modal-sm"
      :active="modal"
      :center="true"
      @onClose="modal = false"
      @click="modal = !modal"
    >
      <qr-code :uri="'type=prof&id=' + userData['idt_user']" />
    </mega-modal>
    <promo-modal
      ref="promo"
      :userData="user"
      :roundlikes="roundlikes"
      :roundviews="roundviews"
    />
  </mega-card>
</template>

<script>
export default {};
</script>

<style></style>
